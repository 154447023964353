.CellContainer {
  @screen md {
    gap: 0.08rem;
  }
}

.Cell {
  flex: 1 0;
  height: auto;
  padding: 0;

  &::before {
    content: '';
    float: left;
    padding-top: 100%;
  }
}
